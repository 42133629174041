<template>
  <b-modal
    id="select-creative-modal"
    :modal-class="modalClasses"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('select_creative') }}</span>
      </h2>
      <p>{{ $t('select_creative_or_create') }}</p>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <b-tabs v-model="tabIndex" content-class="mt-3" nav-class="default-tabs">
        <!-- CREATIVES LIST TAB -->
        <b-tab :title="$t('existing_creatives')">
          <div class="pb-4 d-flex justify-content-center">
            <form class="default-form w-100" @submit.prevent="search">
              <input
                type="text"
                class="form-control form-control w-100"
                :placeholder="$t('name_or_layout')"
                v-model="filters.search"
                @keyup="search"
              />
            </form>
          </div>
          <div v-if="creatives.length === 0 && loader">
            <Loader
              :size="'big'"
            />
          </div>
          <div v-else-if="creatives.length === 0">
            <p>{{ $t('no_creatives_found') }}</p>
          </div>
          <div v-else>
            <div>
              <div
                class="d-flex creative-item py-3 flex-wrap align-items-center mb-2"
                :class="{
                  selected: checkIfIsSelectedById(creative.id),
                  inactive: creative.format !== 'YOUTUBE' && creative.status !== 'ACTIVE'
                }"
                v-for="(creative, index) in creatives"
                :key="index"
                @click="handleClick(creative)"
                :id="`creative-${index}`"
              >
                <div class="col-lg-3">
                  <img :src="creative.file" alt="" style="max-width: 100px;" />
                </div>
                <div class="col-lg-6 text-start">
                  <p class="mb-0">{{ creative.name }}</p>
                  <p class="mb-0">{{ creative.format | formatCreativeFormat }} | {{ creative.layout }}</p>
                </div>
                <div class="col-lg-3">
                  <Button type="button" class="btn btn-xs btn-primary">
                    {{ checkIfIsSelectedById(creative.id) ? $t('selected2') : $t('select2') }}
                  </Button>
                </div>
                <b-tooltip :target="`creative-${index}`" :title="$t('creative_has_error')" v-if="creative.format !== 'YOUTUBE' && creative.status !== 'ACTIVE'"></b-tooltip>
              </div>
            </div>
            <div class="text-center mt-3">
              <Button
                type="button"
                class="btn btn-primary btn-sm"
                @click="loadMore"
                :loading="loader"
                v-if="isLoadMoreVisible"
              >
                {{ $t('load_more') }}
              </Button>
            </div>
            <div class="text-center mt-3">
              <Button
                type="button"
                class="btn btn-primary"
                @click="selectCreative"
                :disabled="selectedCreatives.length === 0"
              >
                {{ $t('select2') }}
              </Button>
            </div>
          </div>
        </b-tab> <!-- CREATIVES LIST TAB -->
        <!-- CREATE TAB -->
        <b-tab title="Adicionar novo">
          <UpsertCreativeForm
            :advertiserId="advertiserId"
            :format="format"
            @creative-created="reset"
            @upsert-creative-error="handleError"
            @format-selected="handleFormatSelected"
          />
        </b-tab> <!-- CREATE TAB -->
      </b-tabs>
    </div>
  </b-modal>
</template>

<script>

// import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CreativesService from '@/modules/creatives/services/creatives-service'
// import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import UpsertCreativeForm from '@/components/creatives/UpsertCreativeForm'

export default {
  name: 'SelectCreativeModal',
  props: ['advertiserId', 'format', 'isSingleSelect', 'layout'],
  components: {
    AlertWraper,
    Button,
    Loader,
    UpsertCreativeForm
  },
  data () {
    return {
      loader: false,
      creatives: [],
      content: null,
      content_type: 'error',
      filters: {
        search: ''
      },
      isLoadMoreVisible: false,
      page: 1,
      selectedCreatives: [],
      selectedFormat: '',
      throttleSearch: null,
      tabIndex: 0
    }
  },
  computed: {
    /**
     * Modal classes
     */
    modalClasses () {
      const classes = ['default-modal']

      if (
        this.tabIndex === 1 &&
        (
          this.selectedFormat === 'NATIVE_DISPLAY' ||
          this.selectedFormat === 'PUSH' ||
          this.format === 'NATIVE_DISPLAY' ||
          this.format === 'PUSH'
        )
      ) {
        classes.push('modal-size-lg')
      }

      return classes.join(' ')
    }
  },
  methods: {
    /**
     * Check if creative is selected
     */
    checkIfIsSelectedById (id) {
      const index = this.selectedCreatives.findIndex(item => {
        return id === item.id
      })
      return index >= 0
    },
    /**
     * Get creatives
     */
    async getCreatives () {
      this.loader = true
      try {
        const creatives = await CreativesService.getCreatives({
          advertiserId: this.advertiserId,
          format: this.format,
          page: this.page,
          search: this.filters.search,
          layout: this.layout,
          approved_status: this.format === 'YOUTUBE' ? 'PENDING_CREATION' : ''
        })
        if (this.page === 1) {
          this.creatives = creatives.data
        } else {
          this.creatives = [...this.creatives, ...creatives.data]
        }
        this.isLoadMoreVisible = creatives.meta.current_page !== creatives.meta.last_page
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Search creatives
     */
    search () {
      clearTimeout(this.throttleSearch)
      this.page = 1
      this.throttleSearch = setTimeout(this.searchCreatives, 500)
    },
    /**
     * Handle click
     */
    handleClick (creative) {
      if (creative.format !== 'YOUTUBE' && creative.status !== 'ACTIVE') return
      if (this.isSingleSelect && this.selectedCreatives.length >= 1) return
      // Check if there already is selected
      const index = this.selectedCreatives.findIndex(item => {
        return creative.id === item.id
      })
      if (index < 0) {
        // If isnt selected, add to array
        this.selectedCreatives.push(Object.assign({}, creative))
      } else {
        // Remove from array if is selected
        this.selectedCreatives.splice(index, 1)
      }
    },
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * Handle format selected
     */
    handleFormatSelected (format) {
      this.selectedFormat = format
    },
    /**
     * Hidden
     */
    hidden () {
      this.creatives = []
      this.page = 1
      this.selectedCreatives = []
      this.selectedFormat = ''
      this.content = null
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getCreatives()
    },
    /**
     * Reset
     */
    reset (creative) {
      this.tabIndex = 0
      this.creatives = []
      this.page = 1
      this.selectedCreatives = []
      this.selectedFormat = ''
      this.getCreatives()
    },
    /**
     * Get creatives for search
     */
    searchCreatives () {
      this.creatives = []
      this.getCreatives()
    },
    /**
     * Select creative
     */
    selectCreative () {
      if (this.selectedCreatives.length === 0) return
      this.$emit('creative-selected', this.selectedCreatives)
      this.$bvModal.hide('select-creative-modal')
    },
    /**
     * Shown
     */
    shown () {
      this.getCreatives()
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.creative-item:hover, .creative-item.selected {
  cursor: pointer;
  background: #eee;
}
.creative-item.inactive {
  background: #eee;
  opacity: 0.3;
}
</style>
