<template>
  <div class="creative-list-table">
    <div v-if="loader && localCreatives.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <!-- FILTERS -->
      <div class="d-flex align-items-center mb-4 default-form" v-if="showFilters">
        <span class="me-4">{{ $t('filters') }}</span>
        <div class="me-3">
          <span class="fw-bold form-label me-3">{{ $t('name_or_layout') }}:</span>
          <input
            type="text"
            class="form-control form-control-sm"
            v-model="filters.search"
          />
        </div>
      </div> <!-- END FILTERS -->
      <!-- CARD -->
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>{{ $t('name') }}</th>
                  <th>{{ $t('format') }}</th>
                  <th v-if="format && format === 'YOUTUBE'">Link YouTube</th>
                  <th v-else>{{ $t('size') }}</th>
                  <th>{{ $t('status') }}</th>
                  <th>{{ $t('details') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(creative, index) in localCreatives"
                  :key="index"
                >
                  <td>
                    <a
                      href="#"
                      class="link-color3"
                      v-b-modal="`creative-detail-modal-${creative.id}`"
                      @click.prevent
                    >
                      {{ creative.name }}
                    </a>
                  </td>
                  <td>{{creative.format | formatCreativeFormat }}</td>
                  <td v-if="creative.format !== 'YOUTUBE'">{{ creative.layout }}</td>
                  <td v-else><a :href="creative.youtube_url" target="_blank">{{ creative.youtube_url }}</a></td>
                  <td>
                    {{ creative.status | formatStatus }}
                    <template v-if="creative.status === 'ERROR'">
                      <RetryCreativeCreationOnPlatformButton
                        :creativeId="creative.id"
                        @retry-successful="reset"
                        @retry-error="handleRetryError"
                      />
                    </template>
                  </td>
                  <td>
                    <a
                      href="#"
                      class="link-color2"
                      v-b-tooltip.hover :title="$t('see_more')"
                      v-b-modal="`creative-detail-modal-${creative.id}`"
                      @click.prevent
                    >
                      <i class="fa fa-eye"></i>
                    </a>
                    <CreativeDetailModal
                      :creative="creative"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!loader && localCreatives.length === 0" class="text-center mt-4">
                <p>Não foram encontrados criativos</p>
            </div>
          </div>
          <div class="text-center mt-4">
            <Button
              class="btn-primary btn-sm"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              {{ $t('load_more') }}
            </Button>
          </div>
        </div> <!-- END CARD -->
      </div>
    </template>
  </div>
</template>

<script>

import CreativesService from '@/modules/creatives/services/creatives-service'

import Button from '@/components/common/Button/Button'
import CreativeDetailModal from '@/components/creatives/CreativeDetailModal'
import Loader from '@/components/common/Loader/Loader'
import RetryCreativeCreationOnPlatformButton from '@/components/creatives/RetryCreativeCreationOnPlatformButton'

export default {
  name: 'CreativeListTable',
  props: ['advertiserId', 'creatives', 'format', 'lineId', 'reload', 'search', 'showFilters'],
  components: {
    Button,
    CreativeDetailModal,
    Loader,
    RetryCreativeCreationOnPlatformButton
  },
  data () {
    return {
      filters: {
        search: ''
      },
      localCreatives: [],
      loader: false,
      page: 1,
      throttleSearch: null,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.creatives) {
      this.localCreatives = this.creatives
      this.isLoadMoreVisible = false
    } else {
      this.getCreatives()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    },
    'filters.search' (newValue) {
      clearTimeout(this.throttleSearch)
      this.page = 1
      this.throttleSearch = setTimeout(this.searchCreatives, 500)
    }
  },
  methods: {
    /**
     * Get creatives
     */
    async getCreatives () {
      if (!this.advertiserId && !this.lineId) return false

      this.loader = true
      try {
        let creatives = []
        if (this.advertiserId) {
          creatives = await CreativesService.getCreatives({
            advertiserId: this.advertiserId,
            page: this.page,
            search: this.filters.search
          })
        } else if (this.lineId) {
        }
        if (this.page === 1) {
          this.localCreatives = creatives.data
        } else {
          this.localCreatives = [...this.localCreatives, ...creatives.data]
        }
        this.isLoadMoreVisible = creatives.meta.current_page !== creatives.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getCreatives()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localCreatives = []
      this.getCreatives()
    },
    /**
     * Get creatives for search
     */
    searchCreatives () {
      this.reset()
    }
  }
}
</script>
